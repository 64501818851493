import React, { useCallback, useState, useEffect } from "react";
import { number, array, func } from "prop-types";
import { ButtonSecondary } from "../buttons/button_secondary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Img from "gatsby-image";
import { Modal } from "antd";
import { ButtonLink } from "../buttons";
import { ExternalLink } from "../links";

export const GalleryViewer = ({ selectedImageIndex, images, onModalClose }) => {
  const [selectedImage, setSelectedImage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (selectedImageIndex !== null && selectedImageIndex !== undefined) {
      setSelectedImage(selectedImageIndex);
      setIsModalOpen(true);
    }
  }, [selectedImageIndex]);

  const onModalCancel = useCallback(() => {
    setIsModalOpen(false);
    setSelectedImage(null);
    onModalClose();
  }, [setIsModalOpen, onModalClose]);

  const onChangeModalImage = useCallback(
    idx => () => {
      setSelectedImage(idx);
    },
    [setSelectedImage]
  );

  return (
    <Modal
      visible={isModalOpen}
      // centered={true}
      footer={null}
      width={"90%"}
      style={{ maxWidth: "1200px" }}
      mask={true}
      maskClosable={true}
      onCancel={onModalCancel}
    >
      {selectedImage !== null && images[selectedImage] && (
        <div style={{ margin: "-20px" }}>
          <div style={{ textAlign: "center", marginTop: "8px" }}>
            <ButtonSecondary
              style={{ margin: "4px" }}
              onClick={onChangeModalImage(selectedImage - 1)}
              disabled={selectedImage === 0}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: "4px" }}
              />
              Back
            </ButtonSecondary>
            <ButtonSecondary
              style={{ margin: "4px" }}
              onClick={onChangeModalImage(selectedImage + 1)}
              disabled={selectedImage === images.length - 1}
            >
              Next
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "4px" }}
              />
            </ButtonSecondary>
          </div>
          <Img fluid={images[selectedImage].imagePath.childImageSharp.fluid} />
          {images[selectedImage].homeSlug && (
            <ButtonLink style={{ float: "right", marginTop: "-40px" }}>
              <ExternalLink url={images[selectedImage].homeSlug}>
                See this Home Design
              </ExternalLink>
            </ButtonLink>
          )}
        </div>
      )}
    </Modal>
  );
};
GalleryViewer.propTypes = {
  selectedImageIndex: number,
  images: array,
  onModalClose: func.isRequired,
};
