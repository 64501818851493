/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { Layout as LayoutAntd, Row, Col } from "antd";

import { Header } from "./header";
import { Footer } from "./footer";
import "./layout.css";
import { Nav } from "./nav";
import { Colors } from "./constants/colors";

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allRangesJson {
        nodes {
          name
          slug
        }
      }
      allFloorPlansJson {
        nodes {
          name
          slug
          info {
            size
          }
        }
      }
      allDisplaysJson {
        nodes {
          name
          estate
          slug
          floorPlan {
            size
          }
        }
      }
      allBlogsJson {
        nodes {
          title
          slug
        }
      }
    }
  `);

  return (
    <LayoutAntd>
      <Header
        siteTitle={data.site.siteMetadata.title}
        ranges={data.allRangesJson.nodes}
      />
      <LayoutAntd>
        <LayoutAntd.Content>
          <div
            style={{
              margin: `0 auto`,
              //marginTop: "130px",
              background: Colors.White,
              //maxWidth: 960,
              // padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            <main>{children}</main>
          </div>
          <Footer
            floorPlans={data.allFloorPlansJson.nodes}
            displays={data.allDisplaysJson.nodes}
            blogs={data.allBlogsJson.nodes}
            ranges={data.allRangesJson.nodes}
          />
        </LayoutAntd.Content>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <LayoutAntd.Sider
              className={"app-mobile-menu"}
              reverseArrow={true}
              style={{
                zIndex: 11,
                backgroundColor: Colors.DarkGray,

                //marginTop: "80px",
              }}
              theme="dark"
              defaultCollapsed={true}
              collapsedWidth={0}
              // breakpoint={"md"}
              collapsible={true}
              //width={"100%"}
              zeroWidthTriggerStyle={{
                backgroundColor: Colors.LightGreen,
                color: Colors.DarkGray,
                fontWeight: "bold",
                top: "16px",
              }}
            >
              <Nav mode={"inline"} ranges={data.allRangesJson.nodes} />
            </LayoutAntd.Sider>
          </Col>
        </Row>
      </LayoutAntd>
    </LayoutAntd>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
