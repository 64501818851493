import {
  faFacebook,
  faInstagram,
  faLinkedin,
  // faTwitter,
  faYoutube,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { ExternalLinkTypes } from "../links";

export const SocialMediaOptions = [
  {
    icon: faFacebook,
    title: "Facebook",
    link: ExternalLinkTypes.FacebookLink,
    text: ExternalLinkTypes.FacebookText,
  },
  {
    icon: faInstagram,
    title: "Instagram",
    link: ExternalLinkTypes.InstagramLink,
    text: ExternalLinkTypes.InstagramText,
  },
  {
    icon: faLinkedin,
    title: "LinkedIn",
    link: ExternalLinkTypes.LinkedInLink,
    text: ExternalLinkTypes.LinkedInText,
  },
  {
    icon: faYoutube,
    title: "Youtube",
    link: ExternalLinkTypes.YoutubeChannelLink,
    text: ExternalLinkTypes.YoutubeChannelText,
  },
  // {
  //   icon: faTwitter,
  //   title: "Twitter",
  //   link: ExternalLinkTypes.TwitterLink,
  //   text: ExternalLinkTypes.TwitterText,
  // },
  {
    icon: faPinterest,
    title: "Pinterest",
    link: ExternalLinkTypes.PinterestLink,
    text: ExternalLinkTypes.PinterestText,
  },
];
