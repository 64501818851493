export const StoreyFilterOptions = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "1",
    text: "Single Storey",
  },
  {
    value: "2",
    text: "Double Storey",
  },
];
