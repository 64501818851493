import React from "react";
import { object } from "prop-types";
import logo from "./LogoLandscapeGreenWhite.svg";

export const LogoHeader = ({ style }) => {
  return (
    <img
      alt={"logo_header"}
      src={logo}
      style={{ maxWidth: "200px", ...style }}
    />
  );
};

LogoHeader.propTypes = {
  style: object,
};
