import React from "react";
import { ButtonLink } from "../buttons";
import Img from "gatsby-image";
import { func, object } from "prop-types";

export const GalleryImageDisplay = ({ onClick, imageFluid }) => {
  return (
    <div>
      <ButtonLink
        onClick={onClick}
        style={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          height: "100%",
        }}
      >
        &nbsp;
      </ButtonLink>
      <Img fluid={imageFluid} />
    </div>
  );
};

GalleryImageDisplay.propTypes = {
  onClick: func.isRequired,
  imageFluid: object.isRequired,
};
