import React from "react";
import { any, object, string } from "prop-types";
import { Colors } from "../constants";

export const TitleSection = ({ style, color, children }) => (
  <h2
    style={{
      fontSize: "3.5rem",
      padding: "1rem 0",
      fontWeight: 400,
      color: Colors.DarkGray,
      textTransform: "uppercase",
      "-webkit-text-stroke-width": "1px",
      "-webkit-text-stroke-color": color,
      "-webkit-text-fill-color": "transparent",
      ...style,
    }}
  >
    {children}
  </h2>
);

TitleSection.propTypes = {
  children: any.isRequired,
  style: object,
  color: string,
};
TitleSection.defaultProps = {
  color: Colors.DarkGreen,
};
