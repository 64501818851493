export const RangeFilterOptions = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "bronze",
    text: "Bronze Range",
  },
  {
    value: "platinum",
    text: "Platinum Range",
  },
  {
    value: "grand",
    text: "Grand Range",
  },
];
