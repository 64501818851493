import React from "react";
import { Row, Col } from "antd";
import { Colors } from "../constants/index";
import { ContainerContentFixed } from "../containers/index";
import { LogoFooter } from "../logos/index";

import "./footer.css";
import {
  ExternalLink,
  ExternalLinkTypes,
  InternalLink,
  InternalLinkTypes,
} from "../links";

import { SocialMediaMini } from "../social_media";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const FooterLinksContainer = ({ children }) => (
  <div style={{ padding: "2rem" }}>{children}</div>
);

const colStyles = {
  //borderLeft: "1px solid #eee",
};

const Footer = ({ floorPlans, displays, blogs, ranges }) => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "HIA.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer
      className={"cp-footer"}
      style={{
        backgroundColor: Colors.DarkGray,
        color: "white",
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      }}
    >
      <ContainerContentFixed style={{ padding: 0, textAlign: "left" }}>
        <Row gutter={8}>
          <Col
            lg={6}
            sm={24}
            xs={24}
            style={{ ...colStyles, textAlign: "center" }}
          >
            <FooterLinksContainer>
              <LogoFooter style={{ maxWidth: "120px" }} />
              {/*<h5 style={{ fontWeight: "400", color: Colors.LightGreen }}>*/}
              {/*  Building your Grand Vision, with Perfection!*/}
              {/*</h5>*/}
              {/*<h5 style={{ fontWeight: "200" }}>*/}
              {/*  Delivering well crafted Custom luxury homes in Victoria*/}
              {/*</h5>{" "}*/}
              <SocialMediaMini />
              <br />
              <br />
              <ExternalLink url={ExternalLinkTypes.HIALink}>
                <Img
                  fluid={data.BlogImage.childImageSharp.fluid}
                  style={{ marginTop: "24px", maxWidth: 80, margin: "auto" }}
                />
              </ExternalLink>
            </FooterLinksContainer>
          </Col>
          <Col lg={4} sm={24} xs={24} style={colStyles}>
            <FooterLinksContainer>
              <h4>&nbsp;</h4>

              <ul>
                <li>
                  <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>
                </li>

                <li>
                  <InternalLink url={InternalLinkTypes.Services}>
                    Our Services
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.Reviews}>
                    Client Reviews
                  </InternalLink>
                </li>{" "}
                <li>
                  <InternalLink url={InternalLinkTypes.VideosGallery}>
                    Videos Gallery
                  </InternalLink>
                </li>{" "}
                <li>
                  <InternalLink url={InternalLinkTypes.PhotosGallery}>
                    Photos Gallery
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.Blogs}>
                    Blogs
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.About}>
                    About Us
                  </InternalLink>
                </li>

                {/*/!*<li>*!/*/}
                {/*/!*<InternalLink url={InternalLinkTypes.Team}>*!/*/}
                {/*/!*Our Team*!/*/}
                {/*/!*</InternalLink>*!/*/}
                {/*/!*</li>*!/*/}
                <li>
                  <InternalLink url={InternalLinkTypes.Contact}>
                    Contact Us
                  </InternalLink>
                </li>
              </ul>
            </FooterLinksContainer>
          </Col>
          <Col lg={4} sm={24} xs={24} style={colStyles}>
            <FooterLinksContainer>
              <h4>Our Displays</h4>
              <ul>
                {displays &&
                  displays
                    .filter(d => d.slug !== null)
                    .map(d => (
                      <li key={d.slug}>
                        <InternalLink url={d.slug}>
                          {d.name} {d.floorPlan.size} <br />
                          {/*{d.estate}*/}
                        </InternalLink>
                      </li>
                    ))}
                <li>
                  <InternalLink url={InternalLinkTypes.Displays}>
                    All Displays
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.Inspection}>
                    Private Inspection
                  </InternalLink>
                </li>
              </ul>
            </FooterLinksContainer>
          </Col>
          <Col lg={4} sm={24} xs={24} style={colStyles}>
            <FooterLinksContainer>
              <h4>Our Ranges</h4>
              <ul>
                {ranges.map(r => (
                  <li key={r.name}>
                    <InternalLink url={r.slug}>{r.name}</InternalLink>
                  </li>
                ))}
                <li>
                  <InternalLink url={InternalLinkTypes.InclusionPlatinum}>
                    Platinum Inclusions
                  </InternalLink>
                </li> <li>
                <InternalLink url={InternalLinkTypes.InclusionBronze}>
                  Bronze Inclusions
                </InternalLink>
              </li>
                <li>
                  <InternalLink url={InternalLinkTypes.Facades}>
                    All Facades
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.FloorPlans}>
                    All Floor Plans
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.HomeDesigns}>
                    All Home Designs
                  </InternalLink>
                </li>
              </ul>
            </FooterLinksContainer>
          </Col>

          <Col lg={4} sm={24} xs={24} style={colStyles}>
            <FooterLinksContainer>
              <h4>Get Started</h4>
              <ul>
                <li>
                  <InternalLink url={InternalLinkTypes.GetStarted}>
                    Custom Home
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.GrandStudio}>Grand Studio</InternalLink>
                </li>

                <li>
                  <InternalLink url={InternalLinkTypes.RequestCallback}>
                    Request Callback
                  </InternalLink>
                </li>
                <li>
                  <InternalLink url={InternalLinkTypes.Appointment}>
                    Book Appointment
                  </InternalLink>
                </li>
              </ul>
            </FooterLinksContainer>
          </Col>
        </Row>
      </ContainerContentFixed>
      <div
        style={{
          textAlign: "center",
          //  borderTop: `1px solid ${Colors.LightGray}`,
          padding: "2rem",
          backgroundColor: Colors.DarkGray,
        }}
      >
        <div>
          {" "}
          <InternalLink url={InternalLinkTypes.Privacy}>
            Privacy Policy
          </InternalLink>{" "}
          |{" "}
          <InternalLink url={InternalLinkTypes.Disclaimer}>
            Disclaimer
          </InternalLink>{" "}
          © {new Date().getFullYear()} Grandeur Homes.{" "}
          <span>
            {" "}
            Website built by{" "}
            <ExternalLink
              url={"https://itcians.com.au/referral/?website=GrandeurHomes"}
            >
              ITcians
            </ExternalLink>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
