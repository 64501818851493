import React from "react";
import { any, object } from "prop-types";

export const TitleCard = ({ style, children }) => (
  <h4
    style={{ fontSize: "1.4rem", marginBottom: 0, fontWeight: 400, ...style }}
  >
    {" "}
    {children}
  </h4>
);

TitleCard.propTypes = {
  children: any.isRequired,
  style: object,
};
