import React from "react";

import { ExternalLink } from "../links";

import { SocialMediaOptions } from "./SocialMediaOptions";
import { Colors } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
export const SocialMediaMini = () => (
  <div style={{ textAlign: "center" }}>
    <Space size={"large"}>
      {SocialMediaOptions.map(item => (
        <span title={item.title} key={item.title}>
          <ExternalLink url={item.link}>
            <FontAwesomeIcon
              icon={item.icon}
              style={{
                fontSize: "24px",
                marginTop: "4px",
                color: Colors.Green,
              }}
            />
          </ExternalLink>
        </span>
      ))}
    </Space>
  </div>
);
