import React from "react";
import { string } from "prop-types";
import { Colors } from "../constants";

export const BannerPage = ({
  title,
  subTitle,
  titleStyles,
  subTitleStyles,
}) => (
  <div
    style={{
      backgroundColor: Colors.DarkGray,
      padding: "2rem",
      textAlign: "center",

      // background:
      //   "50% 10% / 150% 150% no-repeat  radial-gradient(ellipse at bottom, #2ba652, #8dc149, #bdd043)",
    }}
  >
    <h1
      style={{
        fontWeight: 400,
        color: Colors.LightGreen,
        fontFamily: "Allura",
        fontSize: "4.5rem",
        ...titleStyles,
      }}
    >
      {title}
    </h1>
    {subTitle && (
      <h4
        style={{
          fontWeight: 400,
          color: Colors.White,
          fontSize: "1.5rem",
          ...subTitleStyles,
        }}
      >
        {" "}
        {subTitle}
      </h4>
    )}
  </div>
);

BannerPage.propTypes = {
  title: string.isRequired,
  subTitle: string,
};
