import React from "react";
import { string } from "prop-types";
import { Colors } from "../constants";
import { ContainerContent } from "./ContainerContent";
import { ButtonSecondary } from "../buttons";
import { InternalLink } from "../links";
import { ButtonPrimary } from "../buttons";

export const ContainerCta = ({
  title,
  subTitle,
  primaryText,
  primaryLink,
  secondaryText,
  secondaryLink,
}) => (
  <div style={{ backgroundColor: Colors.LightGreen, textAlign: "center" }}>
    <ContainerContent>
      {title && (
        <h1 style={{ color: Colors.DarkGray, fontWeight: "100" }}>{title}</h1>
      )}
      {subTitle && (
        <h3 style={{ color: Colors.DarkGray, fontWeight: "200" }}>
          {subTitle}
        </h3>
      )}

      {secondaryLink && secondaryText && (
        <ButtonSecondary style={{ margin: "4px" }}>
          <InternalLink url={secondaryLink}>{secondaryText}</InternalLink>
        </ButtonSecondary>
      )}
      {primaryLink && primaryText && (
        <ButtonPrimary style={{ margin: "4px" }}>
          <InternalLink url={primaryLink}>{primaryText}</InternalLink>
        </ButtonPrimary>
      )}
    </ContainerContent>
  </div>
);

ContainerCta.propTypes = {
  title: string,
  subTitle: string,
  primaryText: string,
  primaryLink: string,
  secondaryText: string,
  secondaryLink: string,
};
