import React from "react";
import { object, any } from "prop-types";
import { Button } from "antd";

export const ButtonPrimary = ({ style, children }) => (
  <Button
    type={"primary"}
    style={{ minWidth: "200px", ...style }}
    size={"large"}
    //shape={"round"}
  >
    {children}
  </Button>
);

ButtonPrimary.propTypes = {
  style: object,
  children: any.isRequired,
};
