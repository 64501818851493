import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Colors } from "../constants/colors";
import { Tag } from "antd";
export const DisplayVideoAvailable = () => {
  return (
    <Tag
      color={Colors.LightGray}
      style={{
        color: "#fff",
        display: "inline-block",
        fontSize: "1.1rem",
        padding: "4px 8px",
      }}
    >
      <span title={"Video Available"}>
        <FontAwesomeIcon icon={faYoutube} style={{ color: Colors.DarkGray }} />
      </span>
    </Tag>
  );
};
