import React from "react";

export const ContentDisclaimer = ({ style }) => {
  return (
    <p style={{ fontSize: "0.8rem", textAlign: "left", ...style }}>
      <b>Disclaimer:</b> Current as at February 2020. Reproduction or reuse of
      any material either in whole or partly is strictly forbidden. The images
      used on the website are for illustrative purpose only and may be portrayed
      for upgrade with additional cost or features not provided by Grandeur
      Homes Group including landscaping, tiling, windows, fencing, façade,
      decoration, rooms, furniture, lighting décor, kitchen and bedrooms. The
      details of façade, rooms and windows may vary from one house to another.
      You may refer to the floor plan or the drawing of house structure for more
      details about the size and dimensions. You may contact your nearest
      Grandeur Group office/display for more details.
    </p>
  );
};
