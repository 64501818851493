import React from "react";
import { string } from "prop-types";

export const Video = ({ url }) => (
  <iframe
    title={"Video"}
    width="100%" //"560"
    height="450" //"315"
    src={url}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

Video.propTypes = {
  url: string.isRequired,
};
