import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Colors } from "../constants/colors";

export const ContentLanguage = () => {
  const data = useStaticQuery(graphql`
    query {
      LanguagesImg: file(relativePath: { eq: "other/languages.png" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const ImgFixed = ({ name, style }) => (
    <Img fixed={data[name].childImageSharp.fixed} style={style} />
  );
  return (
    <div style={{ verticalAlign: "top", textAlign: "center" }}>
      <div style={{ display: "inline-block" }}>
        <h2
          style={{
            fontWeight: "600",
            marginTop: "-56px",
            marginBottom: 0,
            marginRight: "16px",
            color: Colors.DarkGreen,
          }}
        >
          We speak your language
        </h2>
      </div>
      <div
        style={{
          display: "inline-block",
          position: "relative",
          verticalAlign: "bottom",
        }}
      >
        <ImgFixed name={"LanguagesImg"} />
      </div>
      <p>Talk to our team in your preferred language</p>
    </div>
  );
};
