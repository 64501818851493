import React from "react";

import { RangeFilterOptions } from "./RangeFilterOptions";
import { array, func, object } from "prop-types";
import { Filter } from "./Filter";

export const RangeFilter = ({ style, selectedFilter, onChangeFilter }) => {
  return (
    <Filter
      filterOptions={RangeFilterOptions}
      label={"Range"}
      onChangeFilter={onChangeFilter}
      selectedFilter={selectedFilter}
      style={style}
    />
  );
};

RangeFilter.propTypes = {
  selectedFilter: array.isRequired,
  onChangeFilter: func.isRequired,
  style: object,
};
