import React from "react";
import { object, any } from "prop-types";
import { Button } from "antd";

export const ButtonSecondary = props => (
  <Button
    type={"secondary"}
    size={"large"}
    //shape={"round"}
    {...props}
    style={{ minWidth: "200px", ...props.style }}
  >
    {props.children}
  </Button>
);

ButtonSecondary.propTypes = {
  style: object,
  children: any.isRequired,
};
