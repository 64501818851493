import React from "react";
import { string, array } from "prop-types";
import { Colors } from "../constants/colors";

import "./inclusionSection.css";
export const InclusionSection = ({ title, list }) => (
  <div style={{ padding: "0.5rem 2rem" }} className={"cp-inclusion-section"}>
    <h4
      style={{ color: Colors.DarkGreen, fontWeight: 400, marginBottom: "8px" }}
    >
      {title}
    </h4>
    <ul style={{ fontSize: "0.8rem", lineHeight: "1rem" }}>
      {list.map((item, i) => (
        <li key={`item-${i}`} style={{ marginBottom: "4px" }}>
          {item}
        </li>
      ))}
    </ul>
  </div>
);

InclusionSection.propTypes = {
  title: string.isRequired,
  list: array.isRequired,
};
