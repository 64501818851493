import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "antd";
import { Colors } from "./constants";

import { LogoHeader } from "./logos";

import { Nav } from "./nav";

export const Header = ({ siteTitle, ranges }) => {
  return (
    <header
      className={"cp-header"}
      style={{
        background: Colors.DarkGray,
        // position: "fixed",
        width: "100%",
        //zIndex: 100,
        //height: "130px",
        borderBottom: `1px solid ${Colors.LightGreen}`,

        // marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 960,
          //padding: `0.5rem 1rem`,
          //  position: "fixed",
          //height: "80px",

          background: Colors.DarkGray,
        }}
      >
        <div
          style={{
            margin: "0",
            padding: "8px",
            textAlign: "center",
          }}
        >
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {/*<h1*/}
            {/*  style={{*/}
            {/*    fontSize: "1.4rem",*/}
            {/*    marginBottom: "8px",*/}
            {/*    fontWeight: "600",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  GRANDEUR GROUP*/}
            {/*</h1>*/}
            {/*<Img*/}
            {/*  style={{ width: "600px", margin: "auto" }}*/}
            {/*  fluid={data["BannerImage"].childImageSharp.fluid}*/}
            {/*/>*/}
            <LogoHeader style={{ margin: 0 }} />
          </Link>
        </div>
        <Row>
          <Col xs={0} sm={0} md={24}>
            {/*<div style={{ textAlign: "center", marginTop: "-48px" }}>*/}
            {/*  <ButtonSecondary style={{ margin: "4px" }}>*/}
            {/*    <InternalLink url={InternalLinkTypes.Displays}>*/}
            {/*      See Displays*/}
            {/*    </InternalLink>*/}
            {/*  </ButtonSecondary>{" "}*/}
            {/*  <ButtonPrimary style={{ margin: "4px" }}>*/}
            {/*    <InternalLink url={InternalLinkTypes.GetStarted}>*/}
            {/*      Get Started*/}
            {/*    </InternalLink>*/}
            {/*  </ButtonPrimary>*/}
            {/*</div>*/}
            <Nav
              style={{ textAlign: "center", marginTop: "8px" }}
              mode={"horizontal"}
              ranges={ranges}
            />
          </Col>
        </Row>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
