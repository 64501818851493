import React from "react";
import Img from "gatsby-image";
import { object } from "prop-types";

export const ContentFluidImg = ({ img, style }) => (
  <Img style={{ ...style }} fluid={img.childImageSharp.fluid} />
);

ContentFluidImg.propTypes = {
  img: object.isRequired,
};
