import React from "react";
import { Colors } from "../constants";
import { string } from "prop-types";

export const ContentTitle = ({ title }) => {
  return (
    <h1 style={{ fontWeight: "400", color: Colors.DarkGreen }}>{title}</h1>
  );
};

ContentTitle.propTypes = {
  title: string.isRequired,
};
