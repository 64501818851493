import React from "react";

import { ExternalLink } from "../links";
import { ContactListItem } from "../contact/contact_list_item";
import { SocialMediaOptions } from "./SocialMediaOptions";

export const SocialMedia = () => (
  <div style={{ textAlign: "left" }}>
    {SocialMediaOptions.map(item => (
      <ContactListItem
        key={item.title}
        icon={item.icon}
        title={item.title}
        content={<ExternalLink url={item.link}>{item.text}</ExternalLink>}
      />
    ))}
  </div>
);
