import React, { useState, useCallback } from "react";
import { object } from "prop-types";
import {
  Form,
  Input,
  Spin,
  Alert,
  Button,
  Radio,
  Divider,
  Slider,
  Checkbox,
  Select,
} from "antd";
import axios from "axios";
import { Colors } from "../constants";
import { InternalLink } from "../links";
import { InternalLinkTypes } from "../links";

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR",
};
const API = axios.create({
  baseURL: "https://api.grandeurhomes.itcians.com.au",
});
const CustomBuilderForm = ({ facades, floorPlans }) => {
  const [formStatus, setFormStatus] = useState(FORM_STATUS.OPEN);
  const [isTitled, setIsTitled] = useState(null);
  const [hasLand, setHasLand] = useState(null);

  const onFinish = useCallback(values => {
    let msg = "";
    Object.keys(values).forEach(v => {
      const key = v.toUpperCase();
      let value = values[v];

      if (v === "date") value = value.format("ddd DD-MM-YYYY");
      if (v === "time") value = value.format("h:mm a");

      if (value !== undefined) msg += `${key}: ${value}\n`;
    });
    setFormStatus(FORM_STATUS.SUBMITTING);

    API.post("/createlead", {
      source: "Web GetStarted",
      name: values["name"],
      email: values["email"],
      phone: values["phone"],
      comments: msg, //contains all details including name, email phone
    })
      .then(response => {
        if (response) {
          setFormStatus(FORM_STATUS.SUBMIT_SUCCESS);
        }
      })
      .catch(err => {
        setFormStatus(FORM_STATUS.SUBMIT_ERROR);
        console.error("Error submitting form", err);
      });
  }, []);

  const onChangeTitled = useCallback(
    event => {
      setIsTitled(event.target.value);
    },
    [setIsTitled]
  );

  const onChangeHasLand = useCallback(
    event => {
      setHasLand(event.target.value);
    },
    [setHasLand]
  );

  const disableEdit =
    formStatus === FORM_STATUS.SUBMITTING ||
    formStatus === FORM_STATUS.SUBMIT_SUCCESS;
  const formItemLayout = {
    labelCol: { md: { span: 8 }, sm: { span: 24 } },
    wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
  };
  const buttonItemLayout = {
    wrapperCol: { md: { span: 16, offset: 8 }, sm: { span: 24 } },
  };
  return (
    <div
      style={{
        border: `1px solid ${Colors.LightGray}`,
        padding: "32px 32px",
      }}
    >
      <h1 style={{ fontWeight: "400", fontSize: "2rem", textAlign: "center" }}>
        Let's build your home{" "}
      </h1>
      <Form {...formItemLayout} onFinish={onFinish}>
        <Divider>About your dream home</Divider>
        <Form.Item
          label="Range"
          name="range"
          rules={[{ required: true, message: "Range is required" }]}
        >
          <Radio.Group disabled={disableEdit}>
            <Radio.Button value="bronze">Bronze</Radio.Button>
            <Radio.Button value="platinum">Platinum</Radio.Button>
            <Radio.Button value="grand">Grand</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Storey"
          name="Storeys"
          rules={[{ required: true, message: "Storeys is required" }]}
        >
          <Radio.Group disabled={disableEdit}>
            <Radio.Button value="1">Single</Radio.Button>
            <Radio.Button value="2">Double</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Bedrooms"
          name="Bedrooms"
          rules={[{ required: true, message: "Bedrooms is required" }]}
        >
          <Slider
            disabled={disableEdit}
            max={6}
            min={1}
            range={true}
            marks={{
              1: "1",
              2: "2",
              3: "3",
              4: "4",
              5: "5",
              6: "6",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Bathrooms"
          name="Bathrooms"
          rules={[{ required: true, message: "Bathrooms is required" }]}
        >
          <Slider
            disabled={disableEdit}
            max={6}
            min={1}
            range={true}
            marks={{
              1: "1",
              2: "2",
              3: "3",
              4: "4",
              5: "5",
              6: "6",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Living Areas"
          name="Living"
          rules={[{ required: true, message: "Living Areas is required" }]}
        >
          <Slider
            disabled={disableEdit}
            max={4}
            min={1}
            range={true}
            marks={{
              1: "1",
              2: "2",
              3: "3",
              4: "4",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Garage"
          name="Garage"
          rules={[{ required: true, message: "Garage is required" }]}
        >
          <Slider
            disabled={disableEdit}
            max={4}
            min={1}
            range={true}
            marks={{
              1: "1",
              2: "2",
              3: "3",
              4: "4",
            }}
          />
        </Form.Item>
        <Form.Item label="Additional features" name="Features">
          <Checkbox.Group>
            {[
              "Alfresco / Outdoor Living",
              "Butler’s Pantry / Butler’s Kitchen",
              "Theatre Room / Rumpus Room",
              "Bulkhead",
              "Powder Room",
              "2 Master Bedrooms",
              "2 Ensuite",
            ].map(f => (
              <div key={f}>
                <Checkbox
                  value={f}
                  style={{ lineHeight: "32px" }}
                  disabled={disableEdit}
                >
                  {f}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label={"Favourite Facades"}
          name={"Facades"}
          extra={
            <span>
              Haven't seen them yet? &nbsp;
              <InternalLink url={InternalLinkTypes.Facades}>
                Browse Facades
              </InternalLink>
            </span>
          }
        >
          <Select mode={"multiple"}>
            {facades.map(f => (
              <Select.Option key={f.name} value={f.name} disabled={disableEdit}>
                {f.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={"Favourite Floor Plans"}
          name={"Floor Plans"}
          extra={
            <span>
              Haven't seen them yet? &nbsp;
              <InternalLink url={InternalLinkTypes.FloorPlans}>
                Browse Floor Plans
              </InternalLink>
            </span>
          }
        >
          <Select mode={"multiple"}>
            {floorPlans.map(f => (
              <Select.Option key={f.name} value={f.name} disabled={disableEdit}>
                {f.name} {f.info.size}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Have you got land?"
          name="Has Land"
          rules={[{ required: true, message: "Have you got land is required" }]}
        >
          <Radio.Group disabled={disableEdit} onChange={onChangeHasLand}>
            <Radio.Button value="Yes">Yes</Radio.Button>
            <Radio.Button value="No">No</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {hasLand === "Yes" && (
          <>
            <Divider>About your land</Divider>

            <Form.Item
              label="Estate Name & Suburb"
              name="Estate"
              rules={[
                { required: true, message: "Estate Name & Suburb is required" },
              ]}
            >
              <Input disabled={disableEdit} />
            </Form.Item>
            <Form.Item
              label="Lot Frontage"
              name="Lot Frontage"
              rules={[{ required: true, message: "Lot Frontage is required" }]}
            >
              <Input disabled={disableEdit} />
            </Form.Item>
            <Form.Item
              label="Lot Depth"
              name="Lot Depth"
              rules={[{ required: true, message: "Lot Depth is required" }]}
            >
              <Input disabled={disableEdit} />
            </Form.Item>
            <Form.Item
              label="Titled"
              name="Titled"
              rules={[{ required: true, message: "Titled is required" }]}
            >
              <Radio.Group disabled={disableEdit} onChange={onChangeTitled}>
                <Radio.Button value="Yes">Yes</Radio.Button>
                <Radio.Button value="No">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {isTitled === "No" && (
              <Form.Item
                label="Expected Title Time"
                name="Expected Title Time"
                rules={[
                  {
                    required: true,
                    message: "Expected Title Time is required",
                  },
                ]}
              >
                <Input disabled={disableEdit} />
              </Form.Item>
            )}
            <Form.Item
              label={"House Budget"}
              name={"House Budget"}
              rules={[
                {
                  required: true,
                  message: "House Budget is required",
                },
              ]}
            >
              <Input disabled={disableEdit} />
            </Form.Item>
          </>
        )}
        {hasLand === "No" && (
          <>
            {" "}
            <Form.Item
              label={"House & Land Budget"}
              name={"House and Land Budget"}
              rules={[
                {
                  required: true,
                  message: "House & Land Budget is required",
                },
              ]}
            >
              <Select>
                {[
                  "Below 300k",
                  "300k - 350k",
                  "350k - 400k",
                  "400k - 450k",
                  "450k - 500k",
                  "500k - 550k",
                  "550k - 600k",
                  "600k - 650k",
                  "650k - 700k",
                  "700k - 750k",
                  "750k - 800k",
                  "Over 800k",
                ].map(f => (
                  <Select.Option key={f} value={f} disabled={disableEdit}>
                    {f}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={"Areas/Suburbs"}
              name={"Areas/Suburbs"}
              rules={[
                {
                  required: true,
                  message: "Areas/Suburbs is required",
                },
              ]}
            >
              <Input disabled={disableEdit} />
            </Form.Item>
          </>
        )}
        <Divider>About you</Divider>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="First Home Buyer"
          name="First Home Buyer"
          rules={[{ required: true, message: "First Home Buyer is required" }]}
        >
          <Radio.Group disabled={disableEdit}>
            <Radio.Button value="Yes">Yes</Radio.Button>
            <Radio.Button value="No">No</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="How did you find us?"
          name="How did you find us"
          // rules={[{ required: true, message: "First Home Buyer is required" }]}
        >
          <Radio.Group disabled={disableEdit}>
            <Radio value="facebook">Facebook</Radio>
            <Radio value="instagram">Instagram</Radio>
            <Radio value="linkedin">LinkedIn</Radio>
            <Radio value="google">Google Search</Radio>
            <Radio value="print">Print ads</Radio>
            <Radio value="word-of-mouth">Word of mouth</Radio>
            <Radio value="other">Other</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea rows={4} disabled={disableEdit} />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disableEdit}
            size={"large"}
            style={{ marginBottom: "8px" }}
          >
            Send
          </Button>
          {formStatus === FORM_STATUS.SUBMITTING && (
            <Spin size="large">
              <Alert
                showIcon
                message="Submitting your request..."
                type="info"
                description="Please wait while we submit your request. This may take a few seconds."
              />
            </Spin>
          )}
          {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
            <Alert
              type="success"
              showIcon
              message="Your request has been sent. We will be in touch soon."
              description=""
            />
          )}
          {formStatus === FORM_STATUS.SUBMIT_ERROR && (
            <Alert
              type="error"
              showIcon
              message="Something went wrong"
              description="There was an error in sending your request. Try again. If error persists, contact directly via email."
            />
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

CustomBuilderForm.propTypes = {
  style: object,
};

export default CustomBuilderForm;
