import React from "react";
import {
  faFilePowerpoint,
  faHome,
  faPencilRuler,
  faPeopleCarry,
  faSignature,
  faSwatchbook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../constants";
import { ContentTitle } from "./ContentTitle";

export const Content6Steps = () => {
  return (
    <>
      <ContentTitle title="6 Steps of Building your home with Grandeur Homes" />

      {[
        { text: "Initial Consultation", icon: faPeopleCarry },
        { text: "Finalise Plans", icon: faPencilRuler },
        { text: "Sign Contract", icon: faSignature },
        { text: "Colour Selection", icon: faSwatchbook },
        { text: "Permit", icon: faFilePowerpoint },
        { text: "On Site", icon: faHome },
      ].map((item, i) => (
        <div
          style={{
            display: "inline-block",
            margin: "8px 8px",
            textAlign: "center",
            minWidth: "100px",
          }}
        >
          <FontAwesomeIcon
            icon={item.icon}
            style={{ color: Colors.DarkGreen, fontSize: "2rem" }}
          />
          <h4 key={`i${i}`}>{item.text} </h4>
        </div>
      ))}
    </>
  );
};
