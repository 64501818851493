import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { ContainerContent } from "../containers";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Image = ({ style, name }) => {
  const data = useStaticQuery(graphql`
    query {
      Img1: file(relativePath: { eq: "customhomes/Img1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img2: file(relativePath: { eq: "customhomes/Img2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img3: file(relativePath: { eq: "customhomes/Img3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img4: file(relativePath: { eq: "customhomes/Img4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img5: file(relativePath: { eq: "customhomes/Img5.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img6: file(relativePath: { eq: "customhomes/Img6.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img7: file(relativePath: { eq: "customhomes/Img7.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img8: file(relativePath: { eq: "customhomes/Img8.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img style={style} fluid={data[name].childImageSharp.fluid} />;
};

const imgStyles = {
  width: "735px",
  height: "auto",
};
export const SliderCustomHomes = ({ style }) => {
  return (
    <div style={{ margin: "0 -16px", ...style }}>
      <Slider
        infinite={true}
        autoplay={true}
        dots={true}
        arrows={false}
        pauseOnHover={true}
        // variableWidth={true}
        slidesToShow={3}
        // slidesToScroll={1}
        responsive={[
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        <Image style={imgStyles} name={"Img1"} />
        <Image style={imgStyles} name={"Img2"} />
        <Image style={imgStyles} name={"Img3"} />
        <Image style={imgStyles} name={"Img4"} />
        <Image style={imgStyles} name={"Img5"} />
        <Image style={imgStyles} name={"Img6"} />
        <Image style={imgStyles} name={"Img7"} />
        <Image style={imgStyles} name={"Img8"} />
      </Slider>
    </div>
  );
};
