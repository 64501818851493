import React from "react";
import { object, any, string } from "prop-types";

export const ExternalLink = ({ style, url, children }) => (
  <a style={style} href={url} target={"_blank"} rel="noopener noreferrer">
    {children}
  </a>
);

ExternalLink.propTypes = {
  style: object,
  url: string.isRequired,
  children: any.isRequired,
};
