import React, { useCallback } from "react";
import { Space, Radio } from "antd";
import { array, func, object, string } from "prop-types";

export const Filter = ({
  style,
  selectedFilter,
  onChangeFilter,
  filterOptions,
  label,
}) => {
  const onChange = useCallback(
    e => {
      onChangeFilter(e.target.value);
    },
    [onChangeFilter]
  );
  return (
    <Space direction={"vertical"}>
      <b>{label}:</b>
      <Radio.Group value={selectedFilter} onChange={onChange} style={style}>
        {filterOptions.map(filter => (
          <Radio.Button key={filter.value} value={filter.value}>
            {filter.text}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Space>
  );
};

Filter.propTypes = {
  selectedFilter: string.isRequired,
  onChangeFilter: func.isRequired,
  filterOptions: array.isRequired,
  label: string.isRequired,
  style: object,
};
