import React from "react";
import { number } from "prop-types";
import { Tag } from "antd";
import { RangeFilterOptions } from "../filters";
import { RANGES_COLORS } from "../constants";
const getText = range => {
  let text = range;
  RangeFilterOptions.forEach(opt => {
    if (opt.value.toString() === range.toString()) text = opt.text;
  });
  return text;
};

export const DisplayRange = ({ range, style }) => (
  <Tag
    color={RANGES_COLORS[range.toString()]}
    style={{
      // color: Colors.DarkGray,
      display: "inline-block",
      fontSize: "1.2rem",
      padding: "4px 8px",
      fontFamily: "Allura",
      ...style,
    }}
  >
    {getText(range)}
  </Tag>
);

DisplayRange.propTypes = {
  range: number.isRequired,
};
