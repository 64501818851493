import React from "react";

import { number, string } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faShower,
  faCar,
  faCouch,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../constants";
import { Space } from "antd";
const DisplayInfoBarItem = ({ icon, value, label, iconColor, valueColor }) => (
  <Space size={"4px"}>
    <span
      style={{
        color: iconColor,
        width: "32px",
        display: "inline-block",
        textAlign: "right",
        fontSize: "1.2rem",
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </span>
    <span
      style={{
        color: valueColor,
        display: "inline-block",
        width: "32px",
        //minWidth: "0px",
        marginLeft: "8px",
        textAlign: "left",
        fontSize: "1.15rem",
      }}
    >
      {value}
    </span>
    {/*<span*/}
    {/*  style={{*/}
    {/*    display: "inline-block",*/}
    {/*    textAlign: "left",*/}
    {/*    fontSize: "1.2rem",*/}
    {/*    color: "#111",*/}
    {/*    marginRight: "6px",*/}
    {/*    width: "100px",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  {label}*/}
    {/*</span>*/}
  </Space>
);

export const DisplayInfoBar = ({ bed, bath, garage, living }) => (
  <Space>
    <DisplayInfoBarItem icon={faBed} value={bed} label={"Bedroom"} />
    <DisplayInfoBarItem icon={faShower} value={bath} label={"Bathroom"} />
    <DisplayInfoBarItem icon={faCouch} value={living} label={"Living"} />
    <DisplayInfoBarItem icon={faCar} value={garage} label={"Garage"} />
  </Space>
);

DisplayInfoBar.propTypes = {
  bed: number.isRequired,
  bath: number.isRequired,
  living: number.isRequired,
  garage: number.isRequired,
  iconColor: string,
  valueColor: string,
};

DisplayInfoBar.defaultProps = {
  iconColor: Colors.DarkGreen,
  valueColors: Colors.DarkGray,
};
