import React from "react";
import { InternalLink } from "../links";
import Img from "gatsby-image";
import { DisplayRange } from "./DisplayRange";
import { DisplayStorey } from "./DisplayStorey";
import { DisplayVideoAvailable } from "./DisplayVideoAvailable";
import { ButtonPrimary } from "../buttons";
import { Row, Col, Card } from "antd";

export const DisplayHomeDesignList = ({ homeDesigns }) => {
  return (
    <Row gutter={[8, 8]}>
      {homeDesigns.map((h, i) => (
        <Col xxl={8} lg={12} md={12} sm={24} xs={24} key={`album-${i}`}>
          <InternalLink url={h.slug}>
            <Card
              hoverable={true}
              cover={
                <Img
                  fluid={h.albumImagePath.childImageSharp.fluid}
                  style={{
                    maxHeight: 320,
                    overflow: "hidden",
                  }}
                />
              }
            >
              {/*<Card.Meta title={<TitleCard>{fp.name}</TitleCard>} />*/}
              {/*<br />*/}

              <DisplayRange range={h.range ? h.range.toString() : "2"} />
              <DisplayStorey storey={h.info.storey} />
              {h.youtubeLink && <DisplayVideoAvailable />}
              <br />
              <br />
              <ButtonPrimary style={{ margin: "auto", display: "block" }}>
                <span>See Home Design</span>
              </ButtonPrimary>
            </Card>
          </InternalLink>
        </Col>
      ))}
    </Row>
  );
};
