import React from "react";
import { object, string, any } from "prop-types";

export const BannerImg = ({ style, imageUrl, children }) => (
  <div style={style}>{children}</div>
);

BannerImg.propTypes = {
  style: object,
  imageUrl: string,
  children: any.isRequired,
};
