import React from "react";
import { object } from "prop-types";
import { Menu } from "antd";
import { InternalLink, InternalLinkTypes } from "./links";
import { Colors } from "./constants";

export const Nav = ({ mode, style, ranges }) => (
  <div style={style}>
    <Menu mode={mode} theme={"dark"} style={{ background: Colors.DarkGray }}>
      <Menu.Item key={InternalLinkTypes.Home}>
        <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Displays}>
        <InternalLink url={InternalLinkTypes.Displays}>
          Our Display Homes
        </InternalLink>
      </Menu.Item>
      <Menu.SubMenu
        key={"/ranges"}
        title={"Our Ranges"}
        style={{ marginBottom: 0 }}
      >
        {ranges.map(range => (
          <Menu.Item key={range.slug}>
            <InternalLink url={range.slug}>{range.name}</InternalLink>
          </Menu.Item>
        ))}
        <Menu.Divider />
        <Menu.Item key={InternalLinkTypes.InclusionPlatinum}>
          <InternalLink url={InternalLinkTypes.InclusionPlatinum}>
            Platinum Inclusions
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.InclusionBronze}>
          <InternalLink url={InternalLinkTypes.InclusionBronze}>
            Bronze Inclusions
          </InternalLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key={InternalLinkTypes.Facades}>
          <InternalLink url={InternalLinkTypes.Facades}>
            All Facades
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.FloorPlans}>
          <InternalLink url={InternalLinkTypes.FloorPlans}>
            All Floor Plans
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.HomeDesigns}>
          <InternalLink url={InternalLinkTypes.HomeDesigns}>
            All Home Designs
          </InternalLink>
        </Menu.Item>
      </Menu.SubMenu>
      {/*<Menu.SubMenu*/}
      {/*  key={"/gallery"}*/}
      {/*  title={"Our Work"}*/}
      {/*  style={{ marginBottom: 0 }}*/}
      {/*>*/}
      {/*  <Menu.Item key={InternalLinkTypes.VideosGallery}>*/}
      {/*    <InternalLink url={InternalLinkTypes.VideosGallery}>*/}
      {/*      Videos Gallery*/}
      {/*    </InternalLink>*/}
      {/*  </Menu.Item>*/}
      {/*  <Menu.Item key={InternalLinkTypes.PhotosGallery}>*/}
      {/*    <InternalLink url={InternalLinkTypes.PhotosGallery}>*/}
      {/*      Photos Gallery*/}
      {/*    </InternalLink>*/}
      {/*  </Menu.Item>*/}
      {/*</Menu.SubMenu>*/}

      <Menu.SubMenu
        key={"/about"}
        title={"About Us"}
        style={{ marginBottom: 0 }}
      >
        <Menu.Item key={InternalLinkTypes.About}>
          <InternalLink url={InternalLinkTypes.About}>About Us</InternalLink>
        </Menu.Item>

        <Menu.Item key={InternalLinkTypes.Services}>
          <InternalLink url={InternalLinkTypes.Services}>
            Our Services
          </InternalLink>
        </Menu.Item>

        <Menu.Item key={InternalLinkTypes.Reviews}>
          <InternalLink url={InternalLinkTypes.Reviews}>
            Client Reviews
          </InternalLink>
        </Menu.Item>{" "}
        <Menu.Item key={InternalLinkTypes.VideosGallery}>
          <InternalLink url={InternalLinkTypes.VideosGallery}>
            Videos Gallery
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.PhotosGallery}>
          <InternalLink url={InternalLinkTypes.PhotosGallery}>
            Photos Gallery
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.Blogs}>
          <InternalLink url={InternalLinkTypes.Blogs}>Blogs</InternalLink>
        </Menu.Item>
      </Menu.SubMenu>
      {/*<Menu.Item key={InternalLinkTypes.Team}>*/}
      {/*<InternalLink url={InternalLinkTypes.Team}>Our Team</InternalLink>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key={InternalLinkTypes.Contact}>
        <InternalLink url={InternalLinkTypes.Contact}>Contact Us</InternalLink>
      </Menu.Item>

      <Menu.SubMenu
        key={"/get-started"}
        title={"Get Started"}
        style={{ marginBottom: 0 }}
      >
        <Menu.Item key={InternalLinkTypes.GetStarted}>
          <InternalLink url={InternalLinkTypes.GetStarted}>
            Custom Home
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.GrandStudio}>
          <InternalLink url={InternalLinkTypes.GrandStudio}>
            Grand Studio
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.RequestCallback}>
          <InternalLink url={InternalLinkTypes.RequestCallback}>
            Request Callback
          </InternalLink>
        </Menu.Item>
        <Menu.Item key={InternalLinkTypes.Appointment}>
          <InternalLink url={InternalLinkTypes.Appointment}>
            Book Appointment
          </InternalLink>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  </div>
);

Nav.propTypes = {
  style: object,
};
