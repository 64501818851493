export const ExternalLinkTypes = {
  GeneralPhoneLink: "tel:+61384014472",
  GeneralPhoneText: "+61 3 8401 4472",
  GeneralEmailLink: "mailto:info@grandeurgroup.com.au",
  GeneralEmailText: "info@grandeurgroup.com.au",
  CurrentJobsEmailLink: "mailto:admin@grandeurgroup.com.au",
  CurrentJobsEmailText: "admin@grandeurgroup.com.au",
  MaintenanceEmailLink: "mailto:maintenance@grandeurgroup.com.au",
  MaintenanceEmailText: "maintenance@grandeurgroup.com.au",
  SalesEmailLink: "mailto:sales@grandeurgroup.com.au",
  SalesEmailText: "sales@grandeurgroup.com.au",
  GoogleMapsLink: "https://goo.gl/maps/conGzfffEoqQsTKR8",
  GoogleMapsText: "16/78 Willandra Dr, Epping VIC 3076",
  YoutubeChannelLink:
    "https://www.youtube.com/channel/UCWrny0ckz0Hrb7ramxfMGFg",
  YoutubeChannelText: "Grandeur Homes",
  LinkedInLink: "https://www.linkedin.com/company/grandeurhomesvictoria/",
  LinkedInText: "@grandeurhomesvictoria",
  FacebookLink: "https://www.facebook.com/GrandeurHomesVictoria/",
  FacebookText: "@GrandeurHomesVictoria",
  InstagramLink: "https://www.instagram.com/grandeurhomesvictoria/",
  InstagramText: "@grandeurhomesvictoria",
  TwitterLink: "https://twitter.com/GrandeurHomes",
  TwitterText: "@GrandeurHomes",
  PinterestLink: "https://www.pinterest.com.au/grandeurhomes",
  PinterestText: "@GrandeurHomes",
  ProductReviewLink: "https://www.productreview.com.au/listings/grandeur-homes",
  GoogleBusinessLink: "https://g.page/grandeurhomesvictoria",
  HIALink: "https://hia.com.au/",
};
