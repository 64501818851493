import React, { useState, useCallback } from "react";
import { object } from "prop-types";
import { Form, Input, Spin, Alert, Button } from "antd";
import axios from "axios";
import { Colors } from "../constants";

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR",
};
const API = axios.create({
  baseURL: "https://api.grandeurhomes.itcians.com.au",
});
const CallbackForm = () => {
  const [formStatus, setFormStatus] = useState(FORM_STATUS.OPEN);

  const onFinish = useCallback(values => {
    let msg = "";
    Object.keys(values).forEach(v => {
      const key = v.toUpperCase();
      let value = values[v];

      if (v === "date") value = value.format("ddd DD-MM-YYYY");
      if (v === "time") value = value.format("h:mm a");

      if (value !== undefined) msg += `${key}: ${value}\n`;
    });
    setFormStatus(FORM_STATUS.SUBMITTING);
    API.post("/createlead", {
      source: "Web Callback",
      name: values["name"],
      email: values["email"],
      phone: values["phone"],
      comments: msg, //contains all details including name, email phone
    })
      .then(response => {
        if (response) {
          setFormStatus(FORM_STATUS.SUBMIT_SUCCESS);
        }
      })
      .catch(err => {
        setFormStatus(FORM_STATUS.SUBMIT_ERROR);
        console.error("Error submitting form", err);
      });
  }, []);

  const disableEdit =
    formStatus === FORM_STATUS.SUBMITTING ||
    formStatus === FORM_STATUS.SUBMIT_SUCCESS;
  const formItemLayout = {
    labelCol: { md: { span: 8 }, sm: { span: 24 } },
    wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
  };
  const buttonItemLayout = {
    wrapperCol: { md: { span: 16, offset: 8 }, sm: { span: 24 } },
  };
  return (
    <div
      style={{
        backgroundColor: Colors.LightGray,
        padding: "32px 32px",
      }}
    >
      <h2 style={{ fontWeight: "bold", fontSize: "1rem" }}>Request Callback</h2>
      <Form {...formItemLayout} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>{" "}
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        {/*<Form.Item label="Preferred Date" name="date">*/}
        {/*  <DatePicker disabled={disableEdit} format={"ddd DD-MM-YYYY"} />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item label="Preferred Time" name="time">*/}
        {/*  <TimePicker format={"h:mm a"} disabled={disableEdit} />*/}
        {/*</Form.Item>*/}
        <Form.Item label="Notes" name="notes">
          <Input.TextArea rows={4} disabled={disableEdit} />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disableEdit}
            size={"large"}
            style={{ marginBottom: "8px" }}
          >
            Request Callback
          </Button>
          {formStatus === FORM_STATUS.SUBMITTING && (
            <Spin size="large">
              <Alert
                showIcon
                message="Requesting your callback..."
                type="info"
                description="Please wait while we request your callback. This may take a few seconds."
              />
            </Spin>
          )}
          {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
            <Alert
              type="success"
              showIcon
              message="Your request has been sent. We will be in touch soon."
              description=""
            />
          )}
          {formStatus === FORM_STATUS.SUBMIT_ERROR && (
            <Alert
              type="error"
              showIcon
              message="Something went wrong"
              description="There was an error in requesting your callback. Try again. If error persists, contact directly via email."
            />
          )}
        </Form.Item>
      </Form>{" "}
    </div>
  );
};

CallbackForm.propTypes = {
  style: object,
};

export default CallbackForm;
