import React from "react";
import { StoreyFilterOptions } from "./StoreyFilterOptions";
import { array, func, object } from "prop-types";
import { Filter } from "./Filter";

export const StoreyFilter = ({ style, selectedFilter, onChangeFilter }) => {
  return (
    <Filter
      filterOptions={StoreyFilterOptions}
      label={"Storey"}
      onChangeFilter={onChangeFilter}
      selectedFilter={selectedFilter}
      style={style}
    />
  );
};

StoreyFilter.propTypes = {
  selectedFilter: array.isRequired,
  onChangeFilter: func.isRequired,
  style: object,
};
