import React from "react";
import { object } from "prop-types";

import logo from "./LogoPortraitGreenWhite.svg";
export const LogoFooter = ({ style }) => {
  return (
    <img alt="logo_footer" src={logo} style={{ maxWidth: "250px", ...style }} />
  );
};

LogoFooter.propTypes = {
  style: object,
};
