import React from "react";
import { InternalLink } from "../links";
import { Colors } from "../constants";
import Img from "gatsby-image";
import { TitleFloorPlan } from "../titles";
import { DisplayStorey } from "./DisplayStorey";
import { DisplayInfoBar } from "./DisplayInfoBar";
import { ButtonPrimary, ButtonSecondary } from "../buttons";
import { Row, Col, Card, Space } from "antd";

export const DisplayFloorPlanList = ({ floorplans }) => {
  return (
    <Row gutter={[32, 32]}>
      {floorplans.map(fp => (
        <Col key={fp.name} lg={8} md={8} sm={24} xs={24}>
          <InternalLink url={fp.slug}>
            <Card
              hoverable={true}
              style={{
                textAlign: "center",
              }}
              cover={
                <div
                  style={{
                    borderBottom: `1px solid ${Colors.LightGray}`,
                    background: "white",
                  }}
                >
                  <Img
                    style={{ width: "100%", height: "auto" }}
                    fluid={fp.imagePath.childImageSharp.fluid}
                  />
                </div>
              }
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <Card.Meta
                  title={
                    <div>
                      {fp.displayLink && (
                        <small
                          style={{
                            fontWeight: "bold",
                            float: "right",
                            margin: "6px",
                          }}
                        >
                          ON DISPLAY
                        </small>
                      )}
                      <TitleFloorPlan size={fp.info.size} name={fp.name} />
                    </div>
                  }
                />

                <div>
                  <div style={{ float: "right", marginTop: "-8px" }}>
                    <DisplayStorey storey={fp.info.storey} />
                  </div>
                  <div style={{ margin: "8px 0" }}>
                    <b>FITS LOT:</b> {fp.info.lotWidth}m x {fp.info.lotLength}m
                  </div>
                  <DisplayInfoBar
                    bed={fp.info.bed}
                    bath={fp.info.bath}
                    garage={fp.info.carSpace}
                    living={fp.info.living}
                  />
                </div>
              </div>

              <Space direction={"vertical"}>
                <ButtonPrimary
                  style={{ margin: "16px auto 0 auto", display: "block" }}
                >
                  <span>See {fp.name}</span>
                </ButtonPrimary>
                {fp.displayLink && (
                  <ButtonSecondary
                    style={{ margin: "0 auto", display: "block" }}
                  >
                    <InternalLink url={fp.displayLink}>
                      See Display Home
                    </InternalLink>
                  </ButtonSecondary>
                )}
                {/*<p style={{ fontSize: "0.8rem", color: Colors.Gray }}>*/}
                {/*  Photos are for illustration purposes only.*/}
                {/*</p>*/}
              </Space>
            </Card>
          </InternalLink>
        </Col>
      ))}
    </Row>
  );
};
