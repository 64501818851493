import React from "react";
import { object } from "prop-types";

import { ContactListItem } from "./contact_list_item";
import { ExternalLink, ExternalLinkTypes, InternalLink } from "../links";
import { SocialMedia } from "../social_media";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { InternalLinkTypes } from "../links";
export const ContactInfo = ({ style }) => (
  <div>
    <div>
      <ContactListItem
        icon={faEnvelope}
        title={"General Enquiry Email"}
        content={
          <ExternalLink url={ExternalLinkTypes.GeneralEmailLink}>
            {ExternalLinkTypes.GeneralEmailText}
          </ExternalLink>
        }
      />
      <ContactListItem
        icon={faEnvelope}
        title={"Current Jobs Enquiry Email"}
        content={
          <ExternalLink url={ExternalLinkTypes.CurrentJobsEmailLink}>
            {ExternalLinkTypes.CurrentJobsEmailText}
          </ExternalLink>
        }
      />
      <ContactListItem
        icon={faEnvelope}
        title={"Home Maintenance Enquiry Email"}
        content={
          <ExternalLink url={ExternalLinkTypes.MaintenanceEmailLink}>
            {ExternalLinkTypes.MaintenanceEmailText}
          </ExternalLink>
        }
      />
      {/*<ContactListItem*/}
      {/*icon={faEnvelope}*/}
      {/*title={"Sales Email"}*/}
      {/*content={*/}
      {/*<ExternalLink url={ExternalLinkTypes.SalesEmailLink}>*/}
      {/*{ExternalLinkTypes.SalesEmailText}*/}
      {/*</ExternalLink>*/}
      {/*}*/}
      {/*/>*/}
      <ContactListItem
        icon={faPhone}
        title={"General Phone"}
        content={
          <ExternalLink url={ExternalLinkTypes.GeneralPhoneLink}>
            {ExternalLinkTypes.GeneralPhoneText}
          </ExternalLink>
        }
      />

      <ContactListItem
        icon={faMapMarkerAlt}
        title={"Office Address"}
        content={
          <ExternalLink url={ExternalLinkTypes.GoogleMapsLink}>
            {ExternalLinkTypes.GoogleMapsText}
          </ExternalLink>
        }
      />
      <ContactListItem
        icon={faClock}
        title={"Office Hours"}
        content={
          <InternalLink url={InternalLinkTypes.Appointment}>
            Mon - Fri 9AM - 5PM
          </InternalLink>
        }
      />
      <SocialMedia />
      {/*<ContactListItem title={"Follow us"} content={<SocialMedia />} />*/}
    </div>
  </div>
);

ContactInfo.propTypes = {
  style: object,
};
