import React from "react";
import { InternalLink } from "../links";
import Img from "gatsby-image";
import { TitleCard } from "../titles";
import { DisplayRange } from "./DisplayRange";
import { DisplayStorey } from "./DisplayStorey";
import { ButtonPrimary } from "../buttons";
import { Row, Col, Card } from "antd";

export const DisplayFacadesList = ({ facades }) => {
  return (
    <Row gutter={[8, 8]}>
      {facades.map(fp => (
        <Col key={fp.name} lg={12} md={12} sm={24} xs={24}>
          <InternalLink url={fp.slug}>
            <Card
              hoverable={true}
              cover={
                <Img
                  fluid={fp.imagePath.childImageSharp.fluid}
                  style={{ width: "100%", height: "auto" }}
                />
              }
            >
              <Card.Meta title={<TitleCard>{fp.name}</TitleCard>} />
              <br /> <DisplayRange range={fp.range} />
              <DisplayStorey storey={fp.storey} />
              <br />
              <br />
              <ButtonPrimary style={{ margin: "auto", display: "block" }}>
                See {fp.name}
              </ButtonPrimary>
            </Card>
          </InternalLink>
        </Col>
      ))}
    </Row>
  );
};
