import React from "react";

import { TitleCard } from "../titles";

import { Row, Col, Card, Space } from "antd";
import { ExternalLink, ExternalLinkTypes, InternalLink } from "../links";
import Img from "gatsby-image";
import { ButtonLink } from "../buttons";

export const DisplayReviewList = ({ reviews }) => {
  return (
    <Row gutter={[8, 8]}>
      {reviews.map(r => (
        <Col key={r.client} md={8} sm={24} xs={24}>
          <InternalLink url={r.slug}>
            <Card
              hoverable={true}
              cover={
                r.imagePath &&
                r.imagePath.childImageSharp && (
                  <Img
                    fluid={r.imagePath.childImageSharp.fluid}
                    style={{
                      maxHeight: 210,
                      overflow: "hidden",
                    }}
                  />
                )
              }
            >
              <Card.Meta
                title={<TitleCard>{r.client}</TitleCard>}
                description={
                  <div>
                    <Space>
                      <DisplayPlatform platformId={r.platform} />
                      <span>{r.date}</span>
                    </Space>

                    <div
                      style={{
                        height: "240px",
                        marginTop: "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <b>{r.title}</b>
                      <br /> <br />
                      {r.review.map(row => (
                        <p>{row}</p>
                      ))}
                    </div>
                  </div>
                }
              />
              <br />
              <ButtonLink style={{ margin: "auto", display: "block" }}>
                <span>See Review</span>
              </ButtonLink>
            </Card>
          </InternalLink>
        </Col>
      ))}
    </Row>
  );
};

const DisplayPlatform = ({ platformId }) => {
  switch (platformId) {
    case "product_review":
      return (
        <ExternalLink url={ExternalLinkTypes.ProductReviewLink}>
          Product Review
        </ExternalLink>
      );
    default:
      return platformId;
  }
};
