export const InternalLinkTypes = {
  Home: "/",
  Contact: "/contact",
  About: "/about",
  Team: "/team",
  Appointment: "/appointment",
  Inspection: "/inspection",
  InspectionByDisplay: ({ display }) => `/inspection/?display=${display}`,
  Services: "/services",
  GrandStudio: "/grand-studio",
  Blogs: "/blogs",
  HomeDesigns: "/homedesigns",
  HomeDesignSingleStorey: "/homedesigns/?storey=1",
  HomeDesignDoubleStorey: "/homedesigns/?storey=2",
  FloorPlans: "/floorplans",
  Facades: "/facades",
  Displays: "/displays",
  // Inclusions: "/inclusions",
  InclusionPlatinum: "/inclusions/platinum",
  InclusionBronze: "/inclusions/bronze",
  Gallery: "/gallery",
  PhotosGallery: "/gallery/photos",
  VideosGallery: "/gallery/videos",
  GetStarted: "/get-started",
  Disclaimer: "/policy/disclaimer",
  Privacy: "policy/privacy",
  Range: ({ range }) => `/range/${range}`,
  Reviews: "/reviews",
  RequestCallback: "/request-callback",
};
