import React from "react";
import { string, number } from "prop-types";
import { Colors } from "../constants";

export const TitleFloorPlan = ({ style, name, size }) => (
  <div style={{ marginBottom: "8px", fontSize: "1.4rem", ...style }}>
    <span> {name}</span>
    {size && (
      <span
        style={{
          backgroundColor: Colors.DarkGreen,
          color: "white",
          padding: "2px 8px",
          marginLeft: "8px",
        }}
      >
        {size}
      </span>
    )}
  </div>
);

TitleFloorPlan.propTypes = {
  name: string.isRequired,
  size: number.isRequired,
};
