import React from "react";
import { number } from "prop-types";
import { Tag } from "antd";
import { Colors } from "../constants";
import { StoreyFilterOptions } from "../filters";

const getStoreyText = storey => {
  let text = storey;
  StoreyFilterOptions.forEach(opt => {
    if (opt.value && opt.value.toString() === storey.toString())
      text = opt.text;
  });
  return text;
};

export const DisplayStorey = ({ storey }) => (
  <Tag
    color={Colors.LightGray}
    style={{
      color: Colors.DarkGray,
      display: "inline-block",
      fontSize: "0.9rem",
      padding: "4px 8px",
    }}
  >
    {getStoreyText(storey)}
  </Tag>
);

DisplayStorey.propTypes = {
  storey: number.isRequired,
};
